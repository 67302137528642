import moment from 'moment'
import i18n from '@/utils/lang'
export default {
  loading: false,
  lang: localStorage.getItem('lang') || 'zh',
  locale: JSON.parse(localStorage.getItem('locale')) || '',
  userSession:
    (localStorage.getItem('userSession') && JSON.parse(localStorage.getItem('userSession'))) || '',
  currentShop:
    localStorage.getItem('currentShop') && JSON.parse(localStorage.getItem('currentShop')),
  partyId:
    (localStorage.getItem('userSession') &&
      JSON.parse(localStorage.getItem('userSession')).currentBu.partyId) ||
    '',
  exclude: null,
  panes: JSON.parse(localStorage.getItem('panes')) || [{ view: 'saleOrders' }],
  pagination_setting: {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
  nowPlatformList:
    localStorage.getItem('nowPlatformList') &&
    JSON.parse(localStorage.getItem('nowPlatformList')) &&
    [],
  shopList: localStorage.getItem('shopList') && JSON.parse(localStorage.getItem('shopList')),
  show_time_setting: {
    hideDisabledOptions: true,
    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
  },
  bizModeList: JSON.parse(localStorage.getItem('bizModeList')) || {},
  currencyMap: ['CNY', 'JPY', 'GBP', 'THB', 'USD', 'TWD', 'PHP'],
  // 状态列表
  purchaseStatusList: ['WAIT_CHECK', 'WAIT_INBOUND', 'PART_INBOUND', 'FINISH', 'CANCEL'], //采购单状态
  transferStatusList: [
    'WAIT_CHECK',
    'WAIT_PUSH',
    'WAIT_OUTBOUND',
    'WAIT_INBOUND',
    'PART_INBOUND',
    'CANCEL',
    'FINISH',
    'PUSH_FAIL',
  ], //调拨单状态
  purchaseReturnStatusList: [
    'WAIT_CHECK',
    'WAIT_PUSH',
    'WAIT_OUTBOUND',
    'FINISH',
    'CANCEL',
    'PUSH_FAIL',
  ], //采购退货状态
  goodsTypeMapping: {
    GENERAL: i18n.t('成品'),
    SEMI_FINISHED_PRODUCT: i18n.t('半成品'),
    RAW_MATERIAL: i18n.t('原料'),
    VIRTUAL: i18n.t('虚拟商品'),
    GIFT: i18n.t('赠品'),
  },
  saleTypeMapping: {
    FRESHLY_MADE_AND_SOLD: i18n.t('现制现售'),
    STOCK_UP_FOR_SALE: i18n.t('备货销售'),
    PURCHASE_SALE: i18n.t('采购销售'),
    ONLY_FOR_PRODUCTION: i18n.t('生产专用'),
  },
  rate: localStorage.getItem('zoomRate'),
}
