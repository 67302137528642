<template>
  <div id="app">
    <a-config-provider :locale="app_locale">
      <a-spin class="global-spin" :spinning="$store.state.loading">
        <keep-alive :exclude="exclueds">
          <router-view />
        </keep-alive>
      </a-spin>
    </a-config-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import en_US from 'ant-design-vue/lib/locale-provider/en_US'
import th_TH from 'ant-design-vue/lib/locale-provider/th_TH'
import { ConfigProvider } from 'ant-design-vue'
import 'moment/locale/zh-cn'
import { mapState, mapMutations } from 'vuex'
import actions from './qiankun/actions'
import http from '@service/axios'
import api from '@service/api'
export default {
  components: {
    'a-config-provider': ConfigProvider,
  },
  data() {
    return {
      exclueds: '',
    }
  },
  created() {
    // onGlobalStateChange 第二个参数为 true，表示立即执行一次观察者函数
    actions.onGlobalStateChange(({ lang }) => {
      this.changeLang(lang)
    }, true)
    this.getPlatformList()
  },
  computed: {
    ...mapState(['lang', 'locale']),
    app_locale() {
      return this.locale || zh_CN
    },
  },
  mounted() {
    this.$bus.$on('change-lang', (lang) => {
      let locale = zh_CN
      this.$i18n.locale = lang
      switch (lang) {
        case 'zh':
          locale = zh_CN
          break
        case 'en':
          locale = en_US
          break
        case 'th':
          locale = th_TH
          break
        default:
          locale = zh_CN
          break
      }
      this.setState({
        lang,
        locale,
      })
    })
  },
  methods: {
    ...mapMutations(['setState']),
    changeLang(lang) {
      localStorage.setItem('language', lang) // 切换多语言
      this.$store.state.lang = lang
      this.$i18n.locale = lang // 设置给本地的i18n插件
    },
    getPlatformList() {
      let list = JSON.parse(localStorage.getItem('shopList') || '[]')
      let shopPlatformList = list
      http({
        url: api.getCategoryMap,
        type: 'post',
        hasLoading: false,
        success: (res2) => {
          let obj = {},
            map = new Map()
          res2.result?.selectMap?.PLATFORM?.forEach((item) => {
            obj[item.name] = item.desc
          })
          shopPlatformList.forEach((item) => {
            if (!map.has(item.platform)) {
              let platform = `${item.platform}/${item.country?.toLocaleUpperCase()}`
              map.set(platform, {
                code: item.platform,
                text: obj[item.platform] || item.platform,
                country: item.country,
              })
            }
          })
          let nowPlatformList = []
          for (let item of map.values()) {
            nowPlatformList.push(item)
          }
          this.setState({
            shopPlatformList,
            nowPlatformList,
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
